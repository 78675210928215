/* eslint-disable @typescript-eslint/no-unused-vars */
import { Organization, Prisma, User, UserOrganizationRole } from "@prisma/client"
import bcrypt from "bcryptjs"

class UsersService {
  async changeStatus(userId: number, action: string) {
    const { data } = useFetch(`/api/users/${userId}/login`, {
      method: "PUT",
      body: action
    })
    return data.value
  }

  async create(userData: Partial<User>[]) {
    for (const user of userData) {
      if (!user.password) throw createError("Password is required")
    }
    const data = await $fetch<number[]>("/api/users", {
      method: "POST",
      body: userData
    })
    if (!data) {
      throw createError("User(s) not created")
    }

    return data
  }

  async deleteUsers(userIds: number[]) {
    for (const userId of userIds) {
      await useFetch(`/api/users/${userId}`, {
        method: "DELETE"
      })
    }
  }

  async getOne(userId: number) {
    const { data } = await useFetch<User>(`/api/users/${userId}`, {
      method: "GET"
    })
    return data.value
  }

  async getOneByEmail(credentials: { username: string; password: string }) {
    const { data, error } = await useFetch("/api/users/email", {
      method: "post",
      body: { email: credentials.username, password: credentials.password }
    })

    if (!data.value) {
      throw error.value?.data
    }

    return data.value
  }

  async getUserIdByResetKey(resetKey: string) {
    const data = await $fetch<number>(`/api/users/reset-key/${resetKey}`, {
      method: "GET"
    })
    if (!data) throw createError("No data was returned")
    return data
  }

  // Gets one user with array of roles and organizations that they are associated with
  async getUserOrganizations(userId: number) {
    //TODO: Change Partial<Organization> to Organization after testing
    const { data } = await useFetch<Organization[]>(`/api/users/${userId}/organizations`)

    if (!data.value) throw createError("No data was returned")
    return data.value
  }

  async resetPassword(resetKey: string, password: string, userId: number) {
    const data = await $fetch<User>(`/api/users/reset-key/${resetKey}`, {
      method: "PUT",
      body: { password, userId }
    })
    if (!data) throw createError("No data was returned")
    return data
  }

  async update(userId: string, requestData: Partial<User>, code: string) {
    const data = await $fetch<User>(`/api/users/${userId}`, {
      method: "PUT",
      body: { requestData, code }
    })
    return data
  }

  async validatePhone(phone: string) {
    try {
      const data = await $fetch(`/api/users/validate-phone`, {
        method: "put",
        body: phone
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }
}

export const usersService = new UsersService()
